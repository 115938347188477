<template>
  <div class="container">
    <div class="select">
      <div class="brand">
        品牌：
        <el-select v-model="brandValue" clearable placeholder="请选择">
          <el-option v-for="item in brandOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="brand">
        分类：
        <el-select clearable v-model="typeValue" placeholder="请选择">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <!-- <div class="brand">
        贸易类型：
        <el-select v-model="tardeValue" clearable placeholder="请选择">
          <el-option v-for="item in tradeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div> -->
      <div class="brand">
        库存状态：
        <el-select v-model="stockValue" clearable placeholder="请选择">
          <el-option v-for="item in stockOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="export">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="input" placeholder="商品条码/商品名称"></el-input>
      </div>
      <el-button type="primary" @click="getProductList('nopage')">查询</el-button>
      <el-button type="info" @click="productExport">导出</el-button>
      <!-- <div class="loadBox">
        <el-link type="primary" @click="loadHY">荒野盛宴控价表下载</el-link>
        <el-link type="primary" style="margin-left:80px" @click="loadFM">福摩控价表下载</el-link>
      </div> -->
    </div>
    <div class="table">
      <el-table border :data="productList" :cell-style="{ textAlign: 'center' }" :header-cell-style="cell"
        @selection-change="handleSelectionChange" stripe style="width: 100%">
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- <el-table-column type="index" label="编号"></el-table-column> -->
        <!-- <el-table-column prop="item_id" label="商品ID" >
          <template slot-scope="scope">
            {{ scope.row.item_id || '-' }}
          </template>
        </el-table-column> -->
        <el-table-column prop="item_img_url" width="170" label="图片">
          <template slot-scope="scope">
            <el-popover placement="right" width="400" trigger="click">
              <img :src="scope.row.item_img_url" style="width: 400px; heihgt: 400px" class="enlarge" />
              <el-button slot="reference" style="border: 0; padding: 0">
                <img :src="scope.row.item_img_url" class="head_pic" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="item_name" label="名称">
          <template slot-scope="scope">
            {{ scope.row.item_name || '-' }}
          </template>
        </el-table-column>

        <el-table-column prop="cate_name" width="140" label="分类">
          <template slot-scope="scope">
            {{ scope.row.cate_name || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="brand_name" width="140" label="品牌">
          <template slot-scope="scope">
            {{ scope.row.brand_name || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="item_barcode" label="条码">
          <template slot-scope="scope">
            <div class="item-group">
              <div class="table-item" v-for="item in scope.row.spec_info" :key="item.id">
                {{ item.item_barcode || '-' }}
                <span class="tag" v-for="val in item.active_store_product" :key="val.id">{{ val.promotion_type_value }}</span>
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="single_name" width="200" label="规格">
          <template slot-scope="scope">
            <div class="item-group">
              <div class="table-item" v-for="item in scope.row.spec_info" :key="item.id">
                {{ item.single_name || '-' }}
              </div>
            </div>

          </template>
        </el-table-column>

        <el-table-column prop="batch_price" label="发货价" width="140">
          <template slot-scope="scope">
            <div class="item-group">
              <div class="table-item" v-for="item in scope.row.spec_info" :key="item.id">
                {{ item.batch_price }}
              </div>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background :hide-on-single-page="false" :current-page="page" @current-change="curretnChange"
        @size-change="handleSizeChange" :page-sizes="[10, 20, 30, 40]" @prev-click="curretnChange"
        @next-click="curretnChange" layout="total,prev,sizes, pager, next,jumper" :total="total"></el-pagination>
    </div>
    <!-- 弹出层 -->
    <el-dialog title="商品详情" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="dialogVisible"
      width="50%">
      <div v-if="img_urls" class="htmlContent" v-html="img_urls"></div>
      <div class="imgs" v-else>
        暂无详细信息
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="物料地址" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="dialogVisible2"
      width="50%">
      <div class="imgs">
        <div>百度云盘：{{ material.url || '暂无地址' }}</div>
        <div v-if="material.passwd">密码：{{ material.passwd }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { urlEncode } from '@/utils/util.js'
import { BASE_URL2 } from '../request/request.config';
export default {
  name: 'product',
  data() {
    return {
      tardeValue: '',
      stockValue: '',
      brandValue: '',
      typeValue: '',
      input: '',
      page: 1,
      limit: 10,
      dialogVisible: false,
      dialogVisible2: false,
      img_urls: [],
      multipleSelection: [],
      material: {},
      total: 0,
      stockOptions: [],
      brandOptions: [],
      typeOptions: [],
      tradeOptions: [],
      productList: []
    }
  },
  watch: {

  },
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
      }
    }
  },
  created() {
    this.getProductList()
    this.getProductBrandList()
    this.getProductCateList()
    this.getProductTradeList()
    this.getStockStatusList()
  },
  methods: {
    cell({ row, column, columnIndex }) {
      return { textAlign: 'center' }
    },
    loadFM() {
      window.open('https://source.aidogcatpet.com/saas/Fromm-晟煜寵物 -销售控价表.pdf')
    },
    loadHY() {
      window.open('https://source.aidogcatpet.com/saas/荒野盛宴-鴻業寵物-销售控价表.pdf')
    },
    prevClick(e) { // 点击上一页
      this.page = e
      this.getProductList()
    },
    nextClick(e) { // 点击下一页
      this.page = e
      this.getProductList()
    },
    curretnChange(e) { // 切换page
      this.page = e
      this.getProductList()
    },
    handleClick(e) { // 查看商品详情
      this.img_urls = e.img_urls
      this.dialogVisible = true
    },
    handleClick2(e) { // 查看物料地址
      this.material = e.material
      this.dialogVisible2 = true
    },
    handleSizeChange(e) {
      this.limit = e
      this.getProductList()
    },
    handleSelectionChange(val) { // 选中商品集合
      this.multipleSelection = val
    },
    getProductList(e) { // 获取商品列表
      // eslint-disable-next-line camelcase
      var brand_id = this.brandValue
      // eslint-disable-next-line camelcase
      var cate_id = this.typeValue
      // eslint-disable-next-line camelcase
      var trade_type = this.tardeValue
      // eslint-disable-next-line camelcase
      var stock_status = this.stockValue
      var keyword = this.input
      this.page = e === 'nopage' ? 1 : this.page
      var page = this.page
      var limit = this.limit
      this.$http2.post('/merapi/product/list', { brand_id, cate_id, trade_type, stock_status, keyword, page, limit }).then(res => {
        this.productList = res.data.data.list

        this.total = res.data.data.total
      }).catch(err => {
        this.$message.error(err + '')
      })
    },
    getProductBrandList() { // 获取品牌列表

      this.$http2.post('/merapi/brand/list').then(res => {
        this.brandOptions = res.data.data.list
      }).catch(err => {
        this.$message.error(err + '')
      })
    },
    getProductCateList() { // 获取商品分类
      this.$http2.post('/merapi/category/list').then(res => {
        this.typeOptions = res.data.data.list
      }).catch(err => {
        this.$message.error(err + '')
      })
    },
    getProductTradeList() { // 获取贸易类型
      this.$http2.post('/merapi/product/trade_list').then(res => {
        this.tradeOptions = res.data.data
      }).catch(err => {
        this.$message.error(err + '')
      })
    },
    getStockStatusList() { // 获取库存状态
      this.$http2.post('/merapi/product/stock_status_list').then(res => {
        this.stockOptions = res.data.data
      }).catch(err => {
        this.$message.error(err + '')
      })
    },
    productExport() { // 商品导出

      // eslint-disable-next-line camelcase
      var brand_id = this.brandValue
      // eslint-disable-next-line camelcase
      var cate_id = this.typeValue
      // eslint-disable-next-line camelcase
      var trade_type = this.tardeValue
      // eslint-disable-next-line camelcase
      var stock_status = this.stockValue
      var keyword = this.input
      var multipleSelection = this.multipleSelection
      // eslint-disable-next-line camelcase
      var ids = []
      if (multipleSelection.length > 0) {
        multipleSelection.forEach(ele => {
          ids.push(ele.item_id)
        })
      }
      // eslint-disable-next-line camelcase
      ids = ids.join(',')
      var token = window.localStorage.getItem('token')

      try {
        let params = {
          brand_id, cate_id, trade_type, stock_status, keyword, ids, token
        }
        let data = urlEncode(params)
        console.log(data)
        window.location.href = BASE_URL2 + '/merapi/product/export?' + data
      } catch {
        this.$message.error('服务器繁忙，请稍后重试')
      }
      // this.$http2.get('/merapi/product/export',{ params:{ brand_id, cate_id, trade_type, stock_status, keyword, ids }}).then(res => {
      //   this.$message.success(res.data.msg)
      // }).catch(err => {
      //   this.$message.error(err + '')
      // })
    }
  }
}
</script>

<style scoped lang="less">
.select {
  display: flex;
  align-items: center;

  // justify-content: space-between;
  .brand {
    margin-right: 30px;
  }

  div {
    font-size: 16px;

  }
}

.htmlContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;

  img {
    width: 90%;
  }
}

.head_pic {
  width: 120px;
  height: 120px
}
.tag {
  display: inline-block;
  background-color: #66b1ff;
  color: #fff;
  padding: 0px 8px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 8px;
}
.item-group {
  height: 140px;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.export {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .el-button {
    background-color: #0078fb;
  }
  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 16px;
    }

    .el-input {
      width: 300px;
      font-size: 14px;
    }
  }

  .el-button {
    margin-left: 40px;
  }
}

.loadBox {
  margin-left: 100px;
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  align-items: center;
}
</style>
